import cookie from 'cookie'
import { IncomingMessage } from 'http'

// Packages
import React, { useEffect } from 'react'
import App, { AppContext, AppProps } from 'next/app'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import { DehydratedState, Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { KeycloakCookies, Persistors, SSRKeycloakProvider } from '@react-keycloak/nextjs'
import TagManager from 'react-gtm-module'

// Styles
import '../styles/globals.css'

// Utils
import ClassiCarrosTheme from '../utils/theme'

interface AppWithCookiesProps extends AppProps {
    origin: string
    pageProps: {
        dehydratedState: DehydratedState
    }
    cookies: KeycloakCookies
}

const tagManagerArgs = process.env.NEXT_PUBLIC_GTM_ID ? { gtmId: process.env.NEXT_PUBLIC_GTM_ID } : null

const MyApp = ({ Component, pageProps, cookies, origin }: AppWithCookiesProps) => {
    const [queryClient] = React.useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        refetchOnWindowFocus: false,
                        refetchOnReconnect: false,
                    },
                },
            })
    )

    useEffect(() => {
        if (tagManagerArgs) {
            TagManager.initialize(tagManagerArgs)
        }
    }, [])

    return (
        <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps.dehydratedState}>
                <SSRKeycloakProvider
                    keycloakConfig={{
                        url: process.env.NEXT_PUBLIC_KEYCLOAK_URL as string,
                        realm: process.env.NEXT_PUBLIC_KEYCLOAK_REALM as string,
                        clientId: process.env.NEXT_PUBLIC_KEYCLOAK_CLIENT_ID as string,
                    }}
                    persistor={Persistors.Cookies(cookies)}
                    initConfig={{
                        onLoad: 'check-sso',
                        silentCheckSsoRedirectUri: origin ? origin + '/silent-check-sso.html' : undefined,
                    }}
                >
                    <ThemeProvider theme={ClassiCarrosTheme}>
                        <CssBaseline />
                        <Component {...pageProps} />
                    </ThemeProvider>
                </SSRKeycloakProvider>
            </Hydrate>
            <ReactQueryDevtools />
        </QueryClientProvider>
    )
}

const parseCookies = (req: IncomingMessage | undefined) => {
    if (!req || !req.headers) {
        return {}
    }

    return cookie.parse(req.headers.cookie || '')
}

MyApp.getInitialProps = async (context: AppContext) => {
    let origin
    const appProps = await App.getInitialProps(context)

    if (process.env.NEXT_PUBLIC_BASE_URL) {
        origin = process.env.NEXT_PUBLIC_BASE_URL
    } else if (typeof window !== 'undefined') {
        origin = window.location.origin
    }

    return {
        ...appProps,
        cookies: parseCookies(context.ctx?.req),
        origin,
    }
}

export default MyApp
