// Packages
import { createTheme } from '@material-ui/core/styles'

const ClassiCarrosTheme = createTheme({
    palette: {
        primary: {
            main: '#CD181E',
            light: '#FF5747',
            dark: '#940000',
        },
        secondary: {
            main: '#8E1014',
            light: '#C5473B',
            dark: '#5A0000',
        },
        error: {
            main: '#F44336',
        },
        warning: {
            main: '#FF9800',
        },
        info: {
            main: '#2196F3',
        },
        success: {
            main: '#4CAF50',
        },
    },
})

export default ClassiCarrosTheme
